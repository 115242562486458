import { utm_type } from "../constants/utmType";

const analyticsLayerEvents = {
    onPageLoad: () => {
        if (window) {
            window.analyticsLayer = {};
        }
    },
    // Input Change events
    utmParamChangeValue: (utmType, value) => {
        if (utm_type.UTM_PARAM_CAMPAIGN == utmType) {
            window.analyticsLayer.campaignValue = value;
        } else if (utm_type.UTM_PARAM_MEDIUM == utmType) {
            window.analyticsLayer.mediumValue = value;
        } else if (utm_type.UTM_PARAM_SOURCE == utmType) {
            window.analyticsLayer.sourceValue = value;
        }
    }
}

export default analyticsLayerEvents;
